// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require select2
//= require jquery
//= require jquery_ujs
//= require bootstrap-sprockets 

$(document).ready(function() {
    // Called once after the initial page has loaded
  document.addEventListener(
    'turbo:load',
    () => Components.loadAll(),
    {
      once: true,
    },
  );
  $('.select2_station').select2();
});

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

Rails.start()
ActiveStorage.start()

import "bootstrap"

// jquery

import $ from 'jquery'
global.$ = $
global.jQuery = $

// require('jquery-ui');

//// jquery-ui theme
// require.context('file-loader?name=[path][name].[ext]&context=node_modules/jquery-ui-dist!jquery-ui-dist', true,    /jquery-ui\.css/ );
// require.context('file-loader?name=[path][name].[ext]&context=node_modules/jquery-ui-dist!jquery-ui-dist', true,    /jquery-ui\.theme\.css/ );

const flatpickr = require("flatpickr");
const German = require("flatpickr/dist/l10n/de.js").default.de;
global.German = German
